import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ActivityLeadMaster } from 'src/app/Models/ActivityLeadmaster';
import { ApiService } from 'src/app/Service/api.service';
// import { activityMaster } from 'src/app/pages/ActivityMaster/ActivityMaster1/activityMaster';

@Component({
  selector: 'app-actlead',
  templateUrl: './actlead.component.html',
  styleUrls: ['./actlead.component.css']
})
export class ActleadComponent implements OnInit {
  @Input()
  drawerClose1: Function;
  @Input()
  data: ActivityLeadMaster = new ActivityLeadMaster()
  @Input() datalist = []
  @Input() CUSTOMER_ID
  @Input() allHolidayList = []
  @Input() hmiltype
  @Input() NOTIFICATION_ID: any
  @Input() ok11: boolean
  @Input() drawerData2
  @Input() LOCATIONs = []
  @Input() ccelist = []
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');
  orgId = this.cookie.get('orgId');
  drawerVisible: boolean = false;
  isSpinning = false;
  isSpinning111 = false;

  totalRecords: number;
  pageSize = 5
  drawerTitle: string;
  @Input() CALL_STATUSS: any
  WORK_TYPE: any
  btnLoadMoreStatus = false;
  @Input() IS_CONFIRMATION_DONE: boolean
  // CALL_TYPE:any
  // LOCATION:any
  // CALLED_BY:any
  // BT_NUMBER
  // BOOKING_DATE
  // BT_DATE_TIME

  @Input() EmployeeList24 = []

  @Input() customername
  @Input() mobileno

  constructor(private api: ApiService, private datePipe: DatePipe, private cookie: CookieService, private message: NzNotificationService) { }

  ngOnInit() {
    // this.dept();
    // this.location()
    // this.des()
    // this.getallorg1();
    // this.getEmployeeList()

    // this.getHPromiseEmployeeList();
    // this.getCorporateManagerEmployeeList();
    // this.getDirectorEmployeeList();
    // this.getAccountantEmployeeList();
    // this.getHolidayList()
    this.data.BOOKING_DATE = new Date()
    // this.CALL_STATUSS = 'C'

    // this.filteredOptions = this.options;
  }
  customerID = []
  CCE_ID12: any
  getactivities(dataa) {
    this.isSpinning111 = true;
    this.customerID = dataa
    if (this.CCE_ID12 != undefined && this.CCE_ID12 != null && this.CCE_ID12 != '') {
      this.api.getAllActivityleadMaster(1, this.pageSize, 'ID', 'desc', ` AND VIN = '${dataa['VIN']}' AND EXECUTIVE_ID = ${this.CCE_ID12} `).subscribe(
        data => {
          if (data['code'] == 200) {
            this.totalRecords = data['count'];
            this.datalist = data['data']
            this.isSpinning111 = false;
            console.log(this.datalist);
            if (this.totalRecords == this.datalist.length) {
              this.btnLoadMoreStatus = false;

            } else {
              this.btnLoadMoreStatus = true;
            }
          }
        }, (err) => {
          console.log(err)
          // window.location.reload();
          this.message.error("Cannot perform operation due to unstable Internet connection. Or ", "The server's internet connection is down. Please contact the EDP department for help.");
          // this.drawerClose();
        });
    }
    else {
      this.api.getAllActivityleadMaster(1, this.pageSize, 'ID', 'desc', ` AND VIN = '${dataa['VIN']}' AND EXECUTIVE_ID = ${this.userId} `).subscribe(
        data => {
          if (data['code'] == 200) {
            this.totalRecords = data['count'];
            this.datalist = data['data']
            this.isSpinning111 = false;
            console.log(this.datalist);
            if (this.totalRecords == this.datalist.length) {
              this.btnLoadMoreStatus = false;

            } else {
              this.btnLoadMoreStatus = true;
            }
          }
        }, (err) => {
          console.log(err)
          // window.location.reload();
          this.message.error("Cannot perform operation due to unstable Internet connection. Or ", "The server's internet connection is down. Please contact the EDP department for help.");
          // this.drawerClose();
        });
    }

  }

  data1: ActivityLeadMaster = new ActivityLeadMaster()
  // log(data1: ActivityLeadMaster) {


  //   this.isApproveVisible11 = true;

  //   this.data1 = Object.assign({}, data1);
  //   this.data1.ACTIVITY_TYPE_ID = data1.ACTIVITY_TYPE_ID
  //   if (this.data1.IS_CONFIRMATION_DONE == 1) {
  //     this.IS_CONFIRMATION_DONE = true
  //   }
  //   else {
  //     this.IS_CONFIRMATION_DONE = false
  //   }

  //   this.data1.ACTIVITY_TYPE_ID = 2

  //   if (data1.CALL_STATUS == 'A' || data1.CALL_STATUS == 'R' || data1.CALL_STATUS == 'ML' || data1.CALL_STATUS == 'WD') {
  //     this.CALL_STATUSS = 'C'
  //   } else {
  //     this.CALL_STATUSS = 'N'
  //   }
  // }


  log(data1: ActivityLeadMaster) {


    this.isApproveVisible11 = true;

    this.data1 = Object.assign({}, data1);
    this.data1.ACTIVITY_TYPE_ID = data1.ACTIVITY_TYPE_ID

    if (this.data1.IS_CONFIRMATION_DONE == 1) {
      this.IS_CONFIRMATION_DONE = true
    }
    else {
      this.IS_CONFIRMATION_DONE = false
    }

    console.log(this.data1.IS_CONFIRMATION_DONE, "this.data1.IS_CONFIRMATION_DONE")

    console.log(this.data1, "data1")

    this.data1.ACTIVITY_TYPE_ID = 2

    if (data1.CALL_STATUS == 'A' || data1.CALL_STATUS == 'R' || data1.CALL_STATUS == 'ML' || data1.CALL_STATUS == 'WD') {
      this.CALL_STATUSS = 'C'
    } else {
      this.CALL_STATUSS = 'N'
    }
  }

  isApproveVisible11 = false
  handleCancel2(): void {
    console.log('Button cancel clicked!');
    this.isApproveVisible11 = false;

  }

  showModal2(data): void {
    this.isApproveVisible11 = true;

    // this.api.getappointmentdata(this.pageIndex, this.pageSize, this.sortKey, '', '', '','')
    // .subscribe(
    //   (data) => {
    //     console.log(data);
    //     this.loadingRecords = false;
    //     this.totalRecords = data['count'];
    //     this.dataList = data['data'];
    //     this.isSpinning = false;
    //     this.filterClass = 'filter-invisible';

    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
  }

  onStatuschange(event) {
    if (event == "C") {
      this.data.CALL_STATUS = "R"
    } else {
      this.data.CALL_STATUS = null
    }
    // this.data.CALL_STATUS = ''
  }

  pick_up
  // onStatu(event) {
  //   console.log(event);

  // }
  // close(accountMasterPage: NgForm) {
  //   this.drawerClose();
  resetDrawer(accountMasterPage: NgForm) {
    accountMasterPage.form.reset();
    this.data.BOOKING_TIME = null
    this.data.CALLING_TIME = null

  }
  // }
  ddd: any
  dateFormat = "dd/MM/yyyy"
  dateFormat1 = "HH:mm"
  REMARK1: any
  BOOKING_TIME = new Date()
  // dateFormat2 
  isOk = true;
  inputValue: string;
  filteredOptions: string[] = [];
  options = ['Customer Lost their car ', 'Car was damaged', 'Customer sold their car', 'Customer has serviced the car from outside'];
  onChange(value: string): void {

    this.filteredOptions = this.options.filter(
      (option) => option.toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  }


  save(addNew: boolean, accountMasterPage: NgForm): void {
    this.isSpinning = false;
    this.isOk = true;
    this.data.EXECUTIVE_ID = Number(this.cookie.get('userId'))
    if (this.ok11 != true) {
      this.data.CUSTOMER_ID = this.data.ID
    }
    else {
      this.data.CUSTOMER_ID = this.CUSTOMER_ID
    }

    if (this.hmiltype == 'hmil' || this.data.CATEGORY_STATUS == 'L') {
      this.data.IS_ADDED_BY_HMIL = 'Y'

      if (this.data.CALL_STATUS == 'A' || this.data.CALL_STATUS == 'ML' || this.data.CALL_STATUS == 'WD') {
        this.data.HMIL_LOST_STATUS = 'CD'
      } else {
        this.data.HMIL_LOST_STATUS = 'U'
      }



    }

    console.log(this.ide);
    console.log(this.data.EXECUTIVE_ID);
    console.log(this.data.CUSTOMER_ID, "")



    if (this.data.ACTIVITY_TYPE_ID == null || this.data.ACTIVITY_TYPE_ID == undefined) {
      this.isOk = false;
      this.message.error('Please Select Activity Type', '');
    }

    if (this.IS_CONFIRMATION_DONE == false) {
      if (this.data.CALL_STATUS == null || this.data.CALL_STATUS == undefined) {
        this.isOk = false;
        this.message.error('Please Select Call Status', '');
      }
      // }
      // else{

    }

    if (this.data.CALL_STATUS == 'ML') {


      if (this.data.LOST_SUB_CATEGOREY == null || this.data.LOST_SUB_CATEGOREY == undefined || this.data.LOST_SUB_CATEGOREY == '') {
        this.isOk = false;
        this.message.error('Please Select Lost Call Status', '');
      }
    }


    if (this.CALL_STATUSS == 'N' && (this.data.CALLING_STATUS == 'IN' || this.data.CALLING_STATUS == 'NC' || this.data.CALLING_STATUS == 'NR' || this.data.CALLING_STATUS == 'SW')) {
      if (this.data.RESHEDULED_DATE == null || this.data.RESHEDULED_DATE == undefined) {
        this.isOk = false;
        this.message.error('Please Select Reschedule Date', '');
      }

    }
    if (this.data.CALL_STATUS == 'A') {
      // if (this.data.BT_NUMBER == null || this.data.BT_NUMBER == undefined) {
      //   this.isOk = false;
      //   this.message.error('Please Enter BT Number', '');
      // }
      if (this.data.BOOKING_DATE == null || this.data.BOOKING_DATE == undefined) {
        this.isOk = false;
        this.message.error('Please Select Booking Date', '');
      }



      // if(this.data.SERVICE_ADVISER_ID != null && this.data.SERVICE_ADVISER_ID != undefined){

      //   this.data.BOOKING_TIME = this.datePipe.transform(new Date(this.data.BOOKING_TIME), "HH:mm:ss")

      // }

    }
    if (this.data.CALL_STATUS == 'A') {


      if (this.data.SERVICE_ADVISER_ID == null && this.data.SERVICE_ADVISER_ID == undefined) {
        this.isOk = false;
        this.message.error('Please Select service Adviser Date', '');
      }
    }

    if (this.data.CALL_STATUS == 'A') {


      if (this.data.APPOINTMENT_BRANCH == null && this.data.APPOINTMENT_BRANCH == undefined) {
        this.isOk = false;
        this.message.error('Please Select Branch ', '');
      }

      if (this.WORK_TYPE == null && this.WORK_TYPE == undefined) {

        this.isOk = false;

        this.message.error('Please Select Work Type', '');

      }


    }


    if (this.data.CALL_STATUS == 'WD') {


      if (this.data.APPOINTMENT_BRANCH == null && this.data.APPOINTMENT_BRANCH == undefined) {
        this.isOk = false;
        this.message.error('Please Select Branch ', '');
      }
      this.data.REMINDER_DATE = this.datePipe.transform(this.data.REMINDER_DATE, "yyyy-MM-dd")
      this.data.SELF_REMINDER_DATE = this.datePipe.transform(this.data.SELF_REMINDER_DATE, "yyyy-MM-dd")

      if (this.data.SELF_REMINDER_TIME == null || this.data.SELF_REMINDER_TIME == undefined) {

        this.data.SELF_REMINDER_TIME = '10:00:00';

      }
      else {
        this.data.SELF_REMINDER_TIME = this.datePipe.transform(this.data.SELF_REMINDER_TIME, "HH:mm:00")

      }

      if (this.data.REMINDER_TIME == null || this.data.REMINDER_TIME == undefined) {

        this.data.REMINDER_TIME = '10:00:00';

      }
      else {
        this.data.REMINDER_TIME = this.datePipe.transform(this.data.REMINDER_TIME, "HH:mm:00")

      }

    }
    if (this.data.CALL_STATUS == 'R' && this.IS_CONFIRMATION_DONE == false) {
      // if (this.data.REMINDER_DATE == null || this.data.REMINDER_DATE == undefined) {
      //   this.isOk = false;
      //   this.message.error('Please Select Reminder Date', '');
      // }
      if (this.data.SELF_REMINDER_DATE == null || this.data.SELF_REMINDER_DATE == undefined) {
        this.isOk = false;
        this.message.error('Please Select Self Reminder Date', '');
      }

    }



    // if (this.data.CALLING_DATE_TIME == null || this.data.CALLING_DATE_TIME == undefined) {
    //   this.isOk = false;
    //   this.message.error('Please Select Date & Time', '');
    // }


    // this.ddd = this.datePipe.transform(this.data.CALLING_DATE_TIME,"yyyy-MM-dd HH:mm:00")

    // this.data.CALLING_DATE_TIME = this.ddd
    // else{
    if (this.isOk) {



      // this.data.BT_DATE_TIME = this.datePipe.transform(this.data.BT_DATE_TIME, "yyyy-MM-dd HH:mm:00")

      if (this.data.CALL_STATUS == "A") {
        // var date = new Date(this.data.BOOKING_TIME)
        this.data.BOOKING_DATE = this.datePipe.transform(this.data.BOOKING_DATE, "yyyy-MM-dd")
        console.log(this.data.BOOKING_TIME != null && this.data.BOOKING_TIME != undefined, "")
        if (this.data.BOOKING_TIME != null && this.data.BOOKING_TIME != undefined) {

          this.data.BOOKING_TIME = this.datePipe.transform(new Date(this.data.BOOKING_TIME), "HH:mm:ss")

        }
        else {
          this.data.BOOKING_TIME = this.datePipe.transform(new Date(this.BOOKING_TIME), "HH:mm:ss")
        }



        // else{
        //   this.data.BOOKING_TIME = '10:00:00'
        // }

        if (this.data.PICK_UP_TIME != null && this.data.PICK_UP_TIME != undefined) {
          this.data.PICK_UP_TIME = this.datePipe.transform(this.data.PICK_UP_TIME, "HH:mm:ss")

        }
        else {
          this.data.PICK_UP_TIME = '10:00:00'
        }
        this.data.PICK_UP_DATE = this.datePipe.transform(this.data.PICK_UP_DATE, "yyyy-MM-dd")


        this.data.WORK_TYPE = this.WORK_TYPE
      }

      if (this.data.CALL_STATUS == "R") {
        this.data.REMINDER_DATE = this.datePipe.transform(this.data.REMINDER_DATE, "yyyy-MM-dd")
        this.data.SELF_REMINDER_DATE = this.datePipe.transform(this.data.SELF_REMINDER_DATE, "yyyy-MM-dd")

        if (this.data.SELF_REMINDER_TIME == null || this.data.SELF_REMINDER_TIME == undefined) {

          this.data.SELF_REMINDER_TIME = '10:00:00';

        }
        else {
          this.data.SELF_REMINDER_TIME = this.datePipe.transform(this.data.SELF_REMINDER_TIME, "HH:mm:00")

        }

        if (this.data.REMINDER_TIME == null || this.data.REMINDER_TIME == undefined) {

          this.data.REMINDER_TIME = '10:00:00';

        }
        else {
          this.data.REMINDER_TIME = this.datePipe.transform(this.data.REMINDER_TIME, "HH:mm:00")

        }

      }


      if (this.data.CALL_STATUS == "NC" || this.data.CALL_STATUS == "IN" || this.data.CALL_STATUS == "SW" || this.data.CALL_STATUS == "NR") {
        this.data.RESHEDULED_DATE = this.datePipe.transform(this.data.RESHEDULED_DATE, "yyyy-MM-dd")
        this.data.REMINDER_DATE = this.datePipe.transform(this.data.RESHEDULED_DATE, "yyyy-MM-dd")
        // this.data.RESHEDULED_TIME = this.datePipe.transform(this.data.RESHEDULED_TIME, "HH:mm:00")
        // console.log(this.data.RESHEDULED_TIME, "this.data.RESHEDULED_TIME")
        // this.data.RESHEDULED_TIME= this.data.RESHEDULED_TIME

        if (this.data.RESHEDULED_TIME == null || this.data.RESHEDULED_TIME == undefined) {

          this.data.RESHEDULED_TIME = '10:00:00';

        }
        else {
          this.data.RESHEDULED_TIME = this.datePipe.transform(this.data.RESHEDULED_TIME, "HH:mm:00")

        }
      }



      // this.data.CALLING_DATE_TIME = this.datePipe.transform(this.data.CALLING_DATE_TIME, "yyyy-MM-dd")


      this.data.CALLING_DATE_TIME = this.datePipe.transform(this.data.CALLING_DATE_TIME, "yyyy-MM-dd")


      this.data.CALLING_TIME = this.datePipe.transform(this.data.CALLING_TIME, "HH:mm:ss")

















      if (this.data.CALL_STATUS == "WD") {
        this.data.RO_DATE = this.datePipe.transform(this.data.RO_DATE, "yyyy-MM-dd")
      }


      // console.log(this.data.SELF_REMINDER_TIME);
      // console.log(this.data.REMINDER_TIME);

      this.data.REMARK = this.REMARK1
      this.data.IS_CONFIRMATION_DONE = this.IS_CONFIRMATION_DONE
      if (this.IS_CONFIRMATION_DONE == true) {
        this.data.CALL_STATUS = "C"

      }

      if (this.NOTIFICATION_ID != null) {
        this.data.NOTIFICATION_ID = this.NOTIFICATION_ID
      }


      this.data.IS_PICK_UP_AND_DROP = this.data.IS_PICK_UP_AND_DROP

      if (this.data.IS_CONFIRMATION_DONE == 1 && this.data.IS_PICK_UP_AND_DROP == 'Y') {

        //   this.data.IS_PICK_UP_AND_DROP = this.data.PICK_UP_AND_DROP
        // this.data.IS_PICK_UP_AND_DROP= this.data.IS_PICK_UP_AND_DROP

        this.api.updateActivityleadmaster(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.message.success("Activity lead Updated Successfully", "");

            if (!addNew)
              this.drawerClose();

            // this.resetDrawer(accountMasterPage);
            this.isSpinning = false;

          } else {
            this.message.error("Information Updation Failed", "");
            this.isSpinning = false;
          }
        });



      }
      else {
        this.api.creteActivityleadmaster(this.data).subscribe(successCode => {
          if (successCode['code'] == 200) {
            this.isSpinning = false;
            this.message.success("Information Saved Successfully", "");


            this.api.getAllActivityleadMaster(0, 0, 'ID', 'desc', ' AND CUSTOMER_ID = ' + this.data.CUSTOMER_ID).subscribe(data => {
              if (data['code'] == 200) {

                this.datalist = data['data']
                // this.message.success("I")

              }
              // else {
              //   this.message.error("Failed to get leadactivitydetaileses ", "")
              // }
            }, err => {
              console.log(err);
            })

            this.data = new ActivityLeadMaster()
          }



          // else if (successCode['code']==300){
          //   this.message.error(successCode['message'],"")
          //   this.isSpinning = false;
          // }

          else if (successCode['code'] == 300) {
            this.message.error(successCode['message'], "");
            this.isSpinning = false;
            this.drawerClose()
          }

          else {
            this.message.error("Failed To Add Activity", "");
            this.isSpinning = false;
            this.drawerClose()
          }
        });
      }



    }
    // }

  }
  @Input() ActivityTypes = []
  Allactivity = []
  allactions = []
  // LOCATIONs = []
  ACTIVITY_NAME
  dept() {
    this.api.getAllActivityTypeMaster(0, 0, '', '', ' AND STATUS=1').subscribe(data => {
      this.ActivityTypes = data['data'];
      // this.data.ACTIVITY_TYPE_ID = data['data'][0]['ID']

    }, err => {
      this.isSpinning = false;
    });
  }
  branchId = this.cookie.get('branchId')
  location() {
    this.api.getAllBranch(0, 0, '', '', ' AND STATUS=1' + " AND ID=" + this.branchId).subscribe(data => {
      this.LOCATIONs = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }

  getEmployeeList(event) {
    this.allEmployeeList = [];

    this.api.getAllemployeeMaster(0, 0, "", "asc", ` AND ORG_ID = ${this.orgId} AND ROLE_IDS LIKE '%48%' AND BRANCH_ID = '${event}' `).subscribe(data => {
      if (data['code'] == 200) {
        this.EmployeeList24 = data['data'];
      }

    }, (err) => {
          if (this.api.checkOnlineStatus()) {
            console.log(err);
            this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
          } else {
            this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
          }
         });  }


  desig(event) {
    this.data.ACTIVITY_ID = null
    this.api.getAllActivityMaster(0, 0, '', '', ' AND STATUS=1 ' + ' AND ACTIVITY_TYPE_ID = ' + event).subscribe(data => {
      this.Allactivity = data['data'];

    }, err => {
      this.isSpinning = false;
    });
  }
  // des() {

  //   this.api.getAllActivityMaster(0, 0, '', '', ' AND STATUS=1 ').subscribe(data => {
  //     this.Allactivity = data['data'];

  //   }, err => {
  //     this.isSpinning = false;
  //   });
  // }

  // getallorg1() {

  //   this.api.getAllActionMaster(0, 0, 'ID', 'desc', ' ').subscribe(data => {
  //     if (data['code'] == 200) {
  //       this.allactions = data['data'];
  //     }

  //   }, err => {
  //     console.log(err);
  //   });
  // }
  allEmployeeList = [];
  allHPromiseEmployeeList = [];
  allCorporateManagerEmployeeList = [];
  allDirectorEmployeeList = [];
  allAccountantEmployeeList = [];

  employeeList = [];
  hPromiseEmployeeList = [];
  corporateManagerEmployeeList = [];
  directorEmployeeList = [];
  accountantEmployeeList = [];


  today = new Date().getFullYear().toString() + "-" + (new Date().getMonth() + 1).toString()
  month = this.today;
  // allHolidayList = []


  close() {
    this.drawerClose1();
    this.pageSize = 5
    this.api.getAllActivityleadMaster(0, 0, 'ID', 'desc', ' AND CUSTOMER_ID = ' + this.data.ID + ' AND EXECUTIVE_ID =' + this.cookie.get('userId')).subscribe(data => {
      if (data['code'] == 200) {
        this.datalist = data['data']
        console.log(this.datalist, "datalist");

      }
    }, (err) => {
          if (this.api.checkOnlineStatus()) {
            console.log(err);
            this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
          } else {
            this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
          }
         });    // this.resetDrawer(accountMasterPage);
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }
  drawerClose(): void {

    var filter
    if (this.data.ID != null || this.data.ID != undefined) {
      filter = this.data.ID
      console.log(filter)
    }
    else {
      filter = this.CUSTOMER_ID
    }

    this.drawerVisible = false;
    this.api.getAllActivityleadMaster(0, 0, 'ID', 'desc', ' AND CUSTOMER_ID = ' + filter + ' AND EXECUTIVE_ID =' + this.cookie.get('userId')).subscribe(data => {
      if (data['code'] == 200) {

        this.datalist = data['data']
        // this.message.success("I")

      }
      else {
        this.message.error("Failed to get leadactivitydetaileses ", "")
      }
    }, (err) => {
          if (this.api.checkOnlineStatus()) {
            console.log(err);
            this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
          } else {
            this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
          }
         });  }

  drawerData: ActivityLeadMaster = new ActivityLeadMaster()
  ide: number
  add(): void {
    this.drawerData = new ActivityLeadMaster();
    this.drawerTitle = "Create Activity";

    this.ide = this.data.ID
    this.drawerVisible = true;

  }
  added2hrs
  dataList12: any
  add2hr(event) {
    var currenttime = new Date().getTime();
    this.added2hrs = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == this.added2hrs);
    console.log(this.dataList12);
    if (this.dataList12[0].IS_HOLIDAY == 1) {
      this.added2hrs = new Date(currenttime + 2 * 60 * 60 * 1000);

      if (this.CALL_STATUSS == 'N') {
        this.data.RESHEDULED_DATE = this.added2hrs
        this.data.RESHEDULED_TIME = this.added2hrs
        // console.log(this.data.RESHEDULED_TIME);

      }

      else if (event == "RR") {
        this.data.SELF_REMINDER_DATE = this.added2hrs
        this.data.SELF_REMINDER_TIME = this.added2hrs
      }

      else if (event == "DD") {
        this.data.REMINDER_DATE = this.added2hrs
        this.data.REMINDER_TIME = this.added2hrs
      }


    } else {
      this.added2hrs = new Date(currenttime + 2 * 60 * 60 * 1000);
      if (this.CALL_STATUSS == 'N') {
        this.data.RESHEDULED_DATE = this.added2hrs
        this.data.RESHEDULED_TIME = this.added2hrs
      }

      else if (event == "RR") {
        this.data.SELF_REMINDER_DATE = this.added2hrs
        this.data.SELF_REMINDER_TIME = this.added2hrs
      }

      else if (event == "DD") {
        this.data.REMINDER_DATE = this.added2hrs
        this.data.REMINDER_TIME = this.added2hrs
      }
    }


  }
  otherday
  nextday

  addnextday(event) {
    var someDate = new Date();

    var numberOfDaysToAdd = 1;
    this.otherday = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    var datee = this.datePipe.transform(this.otherday, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == datee);
    console.log(this.dataList12, this.dataList12[0]['IS_HOLIDAY'], "this.dataList12['IS_HOLIDAY']");
    if (this.dataList12[0]['IS_HOLIDAY'] == 1) {
      this.message.error("Next Day Is Holiday", '')
    } else {


      if (this.CALL_STATUSS == 'N') {
        this.data.RESHEDULED_DATE = this.otherday
        this.data.RESHEDULED_TIME = this.otherday
      }
      else if (event == "RR") {
        this.data.SELF_REMINDER_DATE = this.otherday
        this.data.SELF_REMINDER_TIME = this.otherday
      }
      else if (event == "DD") {
        this.data.REMINDER_DATE = this.otherday
        this.data.REMINDER_TIME = this.otherday
      }
    }
  }
  added4hrs
  add4hr(event) {
    var currenttime1 = new Date().getTime();
    this.added4hrs = new Date(currenttime1 + 4 * 60 * 60 * 1000);
    console.log(this.added4hrs);
    if (this.CALL_STATUSS == 'N') {
      this.data.RESHEDULED_DATE = this.added4hrs
      this.data.RESHEDULED_TIME = this.added4hrs
    }

    else if (event == "RR") {
      this.data.SELF_REMINDER_DATE = this.added4hrs
      this.data.SELF_REMINDER_TIME = this.added4hrs
    }
    else if (event == "DD") {
      this.data.REMINDER_DATE = this.added4hrs
      this.data.REMINDER_TIME = this.added4hrs
    }
  }
  add2dayss
  twodays

  add2days(event) {

    var someDate = new Date();

    var numberOfDaysToAdd = 2;
    this.twodays = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    var twodate = this.datePipe.transform(this.twodays, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == twodate);
    console.log(this.dataList12);
    if (this.dataList12[0]['IS_HOLIDAY'] == 1) {
      this.message.error("After 2 days There Is Holiday", '')
    } else {

      if (this.CALL_STATUSS == 'N') {
        this.data.RESHEDULED_DATE = this.twodays

        this.data.RESHEDULED_TIME = this.twodays
      }

      else if (event == "RR") {
        this.data.SELF_REMINDER_DATE = this.twodays
        this.data.SELF_REMINDER_TIME = this.twodays
      }
      else if (event == "DD") {
        this.data.REMINDER_DATE = this.twodays
        this.data.REMINDER_TIME = this.twodays
      }

    }
  }
  nextweek
  addnextweek() {
    // var someDate = new Date();
    // var numberOfDaysToAdd = 7;
    // this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    // this.data.RESHEDULED_DATE = this.nextweek
    // this.data.RESHEDULED_TIME = this.nextweek

    var someDate = new Date();

    var numberOfDaysToAdd = 7;
    this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    this.nextweek = this.datePipe.transform(this.nextweek, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == this.nextweek);
    console.log(this.dataList12);
    if (this.dataList12[0].IS_HOLIDAY == 1) {
      this.message.error("After Week There Is Holiday", '')
    } else {
      var numberOfDaysToAdd = 7;
      this.nextday = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
      if (this.CALL_STATUSS == 'N') {
        this.data.RESHEDULED_DATE = this.nextday
        this.data.RESHEDULED_TIME = this.nextday
      }
      // this.data.RESHEDULED_DATE = this.nextday
      // this.data.RESHEDULED_TIME = this.nextday
      else if (this.data.CALL_STATUS == 'R') {
        this.data.SELF_REMINDER_DATE = this.nextday
        this.data.SELF_REMINDER_TIME = this.nextday
      }

    }
  }

  addnextmonth() {
    // var someDate = new Date();
    // var numberOfDaysToAdd = 7;
    // this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    // this.data.RESHEDULED_DATE = this.nextweek
    // this.data.RESHEDULED_TIME = this.nextweek

    var someDate = new Date();

    var numberOfDaysToAdd = 7;
    this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    this.nextweek = this.datePipe.transform(this.nextweek, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == this.nextweek);
    console.log(this.dataList12);
    if (this.dataList12[0].IS_HOLIDAY == 1) {
      this.message.error("After Week There Is Holiday", '')
    } else {
      var numberOfDaysToAdd = 7;
      this.nextday = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
      if (this.CALL_STATUSS == 'N') {
        this.data.RESHEDULED_DATE = this.nextday
        this.data.RESHEDULED_TIME = this.nextday
      }
      // this.data.RESHEDULED_DATE = this.nextday
      // this.data.RESHEDULED_TIME = this.nextday
      if (this.data.CALL_STATUS == 'R') {
        this.data.SELF_REMINDER_DATE = this.nextday
        this.data.SELF_REMINDER_TIME = this.nextday
      }
      // this.data.SELF_REMINDER_DATE = this.nextday
      // this.data.SELF_REMINDER_TIME = this.nextday
    }
  }


  add2hr1() {
    var currenttime = new Date().getTime();
    // this.added2hrs = new Date(currenttime + 2 * 60 * 60 * 1000);



    this.added2hrs = this.datePipe.transform(new Date(), "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == this.added2hrs);
    console.log(this.dataList12);
    if (this.dataList12[0]['IS_HOLIDAY'] == 1) {
      this.added2hrs = new Date(currenttime + 2 * 60 * 60 * 1000);
      this.data.BOOKING_DATE = this.added2hrs
      this.data.BOOKING_TIME = this.added2hrs
      // this.data.SELF_REMINDER_DATE = this.add2hr
      // this.data.SELF_REMINDER_TIME = this.add2hr
    } else {
      this.added2hrs = new Date(currenttime + 2 * 60 * 60 * 1000);
      this.data.BOOKING_DATE = this.added2hrs
      this.data.BOOKING_TIME = this.added2hrs
    }


  }
  nextday1
  addnextday1() {
    var someDate = new Date();

    var numberOfDaysToAdd = 1;
    this.nextday = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    var next = this.datePipe.transform(this.nextday, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == next);
    console.log(this.dataList12);
    if (this.dataList12[0]['IS_HOLIDAY'] == 1) {
      this.message.error("Next Day Is Holiday", '')
    } else {

      this.data.BOOKING_DATE = this.nextday
      this.data.BOOKING_TIME = this.nextday
    }
  }
  added4hrs1
  add4hr1() {
    var currenttime1 = new Date().getTime();
    this.added4hrs = new Date(currenttime1 + 4 * 60 * 60 * 1000);
    console.log(this.added4hrs);

    this.data.BOOKING_DATE = this.added4hrs
    this.data.BOOKING_TIME = this.added4hrs
    // this.data.SELF_REMINDER_DATE = this.added4hrs
    // this.data.SELF_REMINDER_TIME = this.added4hrs
  }
  add2dayss1

  add2days1() {
    // var someDate = new Date();
    // var numberOfDaysToAdd = 2;
    // this.add2dayss = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    // this.data.RESHEDULED_DATE = this.add2dayss
    // this.data.RESHEDULED_TIME = this.add2dayss

    var someDate = new Date();

    var numberOfDaysToAdd = 2;
    this.add2dayss = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    var twod = this.datePipe.transform(this.add2dayss, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == twod);
    console.log(this.dataList12);
    if (this.dataList12[0]['IS_HOLIDAY'] == 1) {
      this.message.error("After 2 days There Is Holiday", '')
    } else {

      this.data.BOOKING_DATE = this.add2dayss
      this.data.BOOKING_TIME = this.add2dayss
      // this.data.SELF_REMINDER_DATE = this.nextday
      // this.data.SELF_REMINDER_TIME = this.nextday
    }
  }
  nextweek1
  addnextweek1() {
    // var someDate = new Date();
    // var numberOfDaysToAdd = 7;
    // this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    // this.data.RESHEDULED_DATE = this.nextweek
    // this.data.RESHEDULED_TIME = this.nextweek

    var someDate = new Date();

    var numberOfDaysToAdd = 7;
    this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    this.nextweek = this.datePipe.transform(this.nextweek, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == this.nextweek);
    console.log(this.dataList12);
    if (this.dataList12[0]['IS_HOLIDAY'] == 1) {
      this.message.error("After Week There Is Holiday", '')
    } else {
      var numberOfDaysToAdd = 7;
      this.nextday = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
      this.data.BOOKING_DATE = this.nextday
      this.data.BOOKING_TIME = this.nextday
      // this.data.SELF_REMINDER_DATE = this.nextday
      // this.data.SELF_REMINDER_TIME = this.nextday
    }
  }

  addnextmonth1() {
    // var someDate = new Date();
    // var numberOfDaysToAdd = 7;
    // this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    // this.data.RESHEDULED_DATE = this.nextweek
    // this.data.RESHEDULED_TIME = this.nextweek

    var someDate = new Date();

    var numberOfDaysToAdd = 7;
    this.nextweek = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
    this.nextweek = this.datePipe.transform(this.nextweek, "yyyy-MM-dd")
    this.dataList12 = this.allHolidayList.filter((item: any) => item.DATE == this.nextweek);
    console.log(this.dataList12);
    if (this.dataList12[0]['IS_HOLIDAY'] == 1) {
      this.message.error("After Week There Is Holiday", '')
    } else {
      var numberOfDaysToAdd = 7;
      this.nextday = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
      this.data.BOOKING_DATE = this.nextday
      this.data.BOOKING_TIME = this.nextday
      // this.data.SELF_REMINDER_DATE = this.nextday
      // this.data.SELF_REMINDER_TIME = this.nextday
    }
  }


  onLoadMoreClick() {
    // this.pageIndex++
    this.pageSize = this.pageSize + 5;
    this.getactivities(this.customerID);
  }

  userId = Number(this.cookie.get('userId'));

  getccewiserecords(CCE_ID) {
    this.isSpinning111 = true;

    if (CCE_ID != undefined && CCE_ID != null && CCE_ID != 0) {

      this.api.getAllActivityleadMaster(1, this.pageSize, 'ID', 'desc', ` AND VIN = '${this.data['VIN']}' AND EXECUTIVE_ID = ${CCE_ID} `).subscribe(
        data => {
          if (data['code'] == 200) {
            this.totalRecords = data['count'];
            this.datalist = data['data']
            this.isSpinning111 = false;
            console.log(this.datalist);
            if (this.totalRecords == this.datalist.length) {
              this.btnLoadMoreStatus = false;

            } else {
              this.btnLoadMoreStatus = true;
            }
          }
        }, (err) => {
          console.log(err)
          // window.location.reload();
          this.message.error("Cannot perform operation due to unstable Internet connection. Or ", "The server's internet connection is down. Please contact the EDP department for help.");
          // this.drawerClose();
        });
      // this.CCE_ID12 = CCE_ID
    } else {
      // this.isSpinning111 = true;
      this.api.getAllActivityleadMaster(1, this.pageSize, 'ID', 'desc', ` AND VIN = '${this.data['VIN']}' AND EXECUTIVE_ID = ${this.userId}`).subscribe(
        data => {
          if (data['code'] == 200) {
            this.totalRecords = data['count'];
            this.datalist = data['data']
            this.isSpinning111 = false;
            console.log(this.datalist);
            if (this.totalRecords == this.datalist.length) {
              this.btnLoadMoreStatus = false;

            } else {
              this.btnLoadMoreStatus = true;
            }
          }
        }, (err) => {
          console.log(err)
          // window.location.reload();
          this.message.error("Cannot perform operation due to unstable Internet connection. Or ", "The server's internet connection is down. Please contact the EDP department for help.");
          // this.drawerClose();
        });
    }

  }

}
