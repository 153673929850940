import { Component, ViewChild } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { ApiService } from './Service/api.service';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Roledetails } from './Models/roledetails';
// import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
// import { ChangePasswordDrawerComponent } from './pages/CommonModule/change-password-drawer/change-password-drawer.component';
import { Attendance } from './Models/Attedance';
import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ViewNotificationDrawerComponent } from './pages/NotificationModule/view-notification-drawer/view-notification-drawer.component';
import { ChangePasswordDrawerComponent } from './pages/change-password-drawer/change-password-drawer.component';


declare const L: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  menus = [];
  isCollapsed = false;
  isLogedIn = false;
  message1;
  formsParent: Roledetails[];
  forms: Roledetails[];
  detailsData: Roledetails = new Roledetails();
  EMAIL_ID = "";
  PASSWORD = "";
  supportKey = "";
  EMPLOYEE_ID
  ORGANIZATION_ID: number;
  drawerVisible: boolean;
  drawerTitle: string;
  drawerData = [];
  // userId = this.cookie.get('userId');
  userId = this.cookie.get('userId')
  USERNAME = this.cookie.get('userName');
  roleId = Number(this.cookie.get('roleId'));
  pageName: string = "";
  pageName2: string = "";
  dataList = [];
  ROLE_ID = Number(this.cookie.get('roleId'));
  org = [];
  shownotify = false;
  currentApplicationVersionForDevelopment: string = environment.firebase.appVersion1;
  currentApplicationVersionForProduction: string = environment.firebase.appVersion2;
  dataList12 = []
  branchId: any = []
  isLoading1: boolean;

  constructor(private router: Router, private api: ApiService, private cookie: CookieService, private message: NzNotificationService, private datePipe: DatePipe) {
    this.loggerInit();
  }
  datalist1 = []
  loggerInit() {
    console.log(this.cookie.get('supportKey') === '');
    if (this.cookie.get('supportKey') === '' || this.cookie.get('supportKey') === null) {
      // console.log("after device id");

      this.api.loggerInit().subscribe(data => {
        // console.log("data from api logger init");
        // console.log(data);

        if (data['code'] == "200") {
          this.cookie.set('supportKey', data["data"][0]["supportkey"], 365, "/", "", false, "Strict");
          // console.log("after init");
        }

      }, (err) => {
        if (this.api.checkOnlineStatus()) {
          console.log(err);

          this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
        } else {

          this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
        }
      })

    } else {
      // console.log("present device id");
      // console.log(this.cookie);
    }
    if (this.roleId == 14) {
      var filter = "AND EMPLOYEE_ID = " + this.userId
      this.api.getHrMapData(filter).subscribe(successCode => {

        this.datalist1 = successCode['data']
        this.dataList12 = successCode['data'].filter((item: any) => item.STATUS == true);
        console.log(this.dataList12, "datalist12")
        for (let i = 0; i < this.dataList12.length; i++) {
          this.branchId.push(this.dataList12[i]['BRANCH_ID'])
          // this.cookie.set('branchId', this.branchId)
          sessionStorage.setItem('branchId', this.branchId)
          // this.cookie.set('branchId', this.branchId)


          // console.log(this.cookie.set('branchId', this.branchId), "")
        }
        console.log(this.branchId);
      })
    }
  }

  setUserId(event: any) {
    this.ROLE_ID = event;
    this.roleId = event;
    sessionStorage.setItem('roleId', this.roleId.toString());

    this.cookie.set('roleId', this.roleId.toString(), 365, "/", "", false, "Strict");
    this.router.navigateByUrl('/common/dashboard');
    this.router.navigate(['/common/dashboard']).then(() => {
      window.location.reload();
    });
  }
  pageName3: any
  ngOnInit() {

    this.cookie.set('selectedLoginType', this.cookie.get("loginType"), 365, "/", "", false, "Strict");

    let url = window.location.href;
    var arr = url.split("/");
    this.pageName2 = arr[3];
    this.pageName3 = arr[4];

    if (this.cookie.get('token') === '' || this.cookie.get('token') === null) {
      this.isLogedIn = false;
      let url = window.location.href;
      var arr = url.split("/");

      if (arr[3] == "adminlogin") {
        this.pageName2 = 'adminlogin';
        this.router.navigateByUrl('/adminlogin');

      } else if (arr[3] == "orglogin") {
        this.pageName2 = 'orglogin';
        this.router.navigateByUrl('/orglogin');

      } else if (arr[3] == "traineraccessorlogin") {
        this.pageName2 = 'traineraccessorlogin';
        this.router.navigateByUrl('/traineraccessorlogin');

      } else {
        this.pageName2 = 'login';
        this.router.navigateByUrl('/login');
      }

    } else {
      if (this.userId || this.roleId != 0) {
        this.isLogedIn = true;
        // this.accessPageForRedirect();
        this.loadForms();

        if (sessionStorage.getItem('topics') == null || sessionStorage.getItem('topics') == '' || sessionStorage.getItem('topics') == undefined) {

          this.api.getChannels().subscribe(data => {
            if (data['code'] == 200) {
              this.cookie.set('channels', data["data"], 365, "/", "", false, "Strict");
              var channels = data["data"].split(',');
              this.api.subscribeTokenToTopic(this.api.cloudID, channels);

            } else {

            }

          }, (err) => {
            if (this.api.checkOnlineStatus()) {
              console.log(err);

              this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
            } else {

              this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
            }
          });
        }


        this.api.getAllEmpRoleMap(0, 0, 'ID', 'ASC', ' AND STATUS=1 AND EMPLOYEE_ID=' + this.userId).subscribe(data => {
          this.dataList = data['data'];

          if (this.roleId == 30) {
            this.getbranches()

          }

        }, (err) => {
          if (this.api.checkOnlineStatus()) {
            console.log(err);

            this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
          } else {

            this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
          }
        });

      } else {
        this.api.logoutForSessionValues();
      }
    }

    this.api.requestPermission(this.userId);
    this.api.receiveMessage();
    var fullName = this.USERNAME.split(' ');

    if (fullName.length == 3)
      this.USERNAME = fullName[1] + ' ' + fullName[0];



    if (this.userId)
      this.getTodaysDayInDayOut();


    if (this.isLogedIn) {
      console.log("This is going to logout automatically after 8 hours");
      window.setTimeout(() => {
        this.cookie.deleteAll();
        window.location.reload();
        this.router.navigateByUrl('/login');
      }, 480 * 60 * 1000);
    }



    if (!this.isLogedIn) {
      console.log("not logged in");
    }
    else {
      console.log("logged in successfully");
      var num = 0
      var totaltime = 480 * 60 * 1000 / 60000;
      var timing = setInterval(() => {
        num += 1000;
        var showminutes = num / 60000;
        // console.log(Math.round(showminutes) + " Minutes, time remains:" + Math.round(totaltime - showminutes) + " Minutes") ;
        if (num == 475 * 60 * 1000) {
          // var minutes = num/60000;
          // console.log(Math.round(minutes));
          this.message.warning("Your Session is about to end in 5 minutes.", "");
        }
        // console.log(num);
      }, 1000);
      if (num == 480 * 60 * 1000) {
        clearInterval(timing);
      }
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.api.showLoading();
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
        this.api.hideLoading();
      }
    });
 
    this.api.loading$.subscribe(isLoading1 => {
      this.isLoading1 = isLoading1;
    });

  }



  accessPageForRedirect() {
    if (this.roleId != 0) {
      let url = window.location.href;
      var arr = url.split("/");
      let validPage = "/" + arr[3]

      console.log(validPage)

      if ((validPage != "/traineraccessorlogin") && (validPage != "/adminlogin") && (validPage != "/login") && (validPage != "/orglogin")) {
        validPage = "/" + arr[3] + "/" + arr[4];
      }
      else if (validPage == "/login" || validPage == "/adminlogin") {
        validPage = "/" + arr[3]
      }



      //  {
      //   this.router.navigateByUrl('/dashboard');
      //   this.router.navigate(['dashboard']).then(() => {
      //     window.location.reload();

      //   });

      // }
      this.api.getCheckAccessOfForm(this.roleId, validPage).subscribe(data => {
        if (data['data'] == true) {
          if (validPage == "/selectpage")
            this.router.navigateByUrl('/common/dashboard')
          else
            this.router.navigateByUrl(validPage)
        }
        else {
          if ((validPage != "/traineraccessorlogin") && (validPage != "/adminlogin") && (validPage != "/login") && (validPage != "/orglogin")) {
            this.api.logoutForSessionValues()
            this.api.logout()
          }
        }
      });
    }
  }

  loadForms() {
    this.api.getForms(this.userId, this.roleId).subscribe(data => {
      if (data['code'] == 200) {
        data['data'].forEach(element => {
          element['children'].sort(this.sortFunction)

          if (element['children'].length == 0)
            delete element['children']
        });

        this.menus = data['data'].sort(this.sortFunction);
      }
    });
  }

  sortFunction(a, b) {
    var dateA = a.SEQ_NO;
    var dateB = b.SEQ_NO;
    return dateA > dateB ? 1 : -1;
  };

  getData(form) {
    this.cookie.set('roleId', form.ROLE_ID, 365, '/', '', false, 'Strict');
  }

  isSpinning = false;

  logout() {
    this.isSpinning = true;
    // let url = window.location.href;
    // var arr = url.split("/");
    // let validPage = "/" + arr[3].replace(/'/g, "")
    this.api.logout().subscribe(forms => {
      // this.cookie.delete("token")
      if (this.roleId != 1) {
        this.api.unsubscribeTokenToTopic(this.api.cloudID);
      }

      setTimeout(() => {
        let deviceID = this.cookie.get('DEVICE_ID');
        // console.log(validPage)
        // this.cookie.delete("DEVICE_ID",deviceID, "/", "", true, "None");
        // this.cookie.delete("token");
        this.deleteAllCookies();
        // this.cookie.set('DEVICE_ID', deviceID, 365, "/", "", false, "Strict");
        sessionStorage.clear();
        window.location.reload();
      }, 1000);



    }, err => {
      // setTimeout(() => {
      //   let deviceID = this.cookie.get('DEVICE_ID');
      //   this.deleteAllCookies();
      //   this.cookie.set('DEVICE_ID', deviceID, 365, "/", "", false, "Strict");
      //   sessionStorage.clear();
      //   window.location.reload();
      // }, 1000);

      // this.isSpinning = false;
      // this.message.error("Failed to Logout", "");
    });
  }

  //  logout() {
  //   this.isSpinning = true;

  //   this.api.logout().subscribe(forms => {
  //     if (this.roleId != 1) {
  //       this.api.unsubscribeTokenToTopic(this.api.cloudID);
  //     }

  //     setTimeout(() => {
  //       let deviceID = this.cookie.get('DEVICE_ID');
  //       // this.cookie.deleteAll();
  //       this.cookie.set('DEVICE_ID', deviceID, 365, "/", "", false, "Strict");
  //       sessionStorage.clear();
  //       window.location.reload();
  //     }, 1000);

  //   }, err => {
  //     setTimeout(() => {
  //       let deviceID = this.cookie.get('DEVICE_ID');
  //       // this.cookie.deleteAll();
  //       this.cookie.set('DEVICE_ID', deviceID, 365, "/", "", false, "Strict");
  //       sessionStorage.clear();
  //       window.location.reload();
  //     }, 1000);

  //     this.isSpinning = false;
  //     this.message.error("Failed to Logout", "");
  //   });
  // }

  deleteAllCookies() {
    // Retrieve all cookies
    const cookies: string[] = document.cookie.split(';');

    // Iterate over each cookie
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const cookieName = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();

      // Explicitly delete the cookie with the root path '/'
      this.cookie.delete(cookieName, '/');
    }
  }


  @ViewChild(ViewNotificationDrawerComponent, { static: false }) ViewNotificationDrawerComponentVar: ViewNotificationDrawerComponent;

  add(): void {
    this.drawerTitle = "Notifications";
    this.drawerVisible = true;
    this.ViewNotificationDrawerComponentVar.pageSize = 8;
    this.ViewNotificationDrawerComponentVar.getNotifications('H');
  }
  // add(): void {
  //   this.drawerTitle = "Notifications"; this.drawerVisible = true;
  //    // this.ViewNotificationDrawerComponentVar.pageSize = 8;    
  //    this.ViewNotificationDrawerComponentVar.getNotifications();  }



  // deleteAllCookies(path) {
  //   const cookies = document.cookie.split(';');

  //   for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i];
  //     const eqPos = cookie.indexOf('=');
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=' + path;
  //   }
  // }

  //  deleteExpiredCookies() {
  //   const cookies = document.cookie.split(';');

  //   const currentTime = new Date().getTime();

  //   for (let i = 0; i < cookies.length; i++) {
  //     const cookie = cookies[i].trim();
  //     const eqPos = cookie.indexOf('=');
  //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     const cookieValue = this.cookie.get(name);
  //     const cookieParams = cookieValue.split(';');

  //     // Check if the cookie has an expiration time
  //     for (let j = 0; j < cookieParams.length; j++) {
  //       const cookieParam = cookieParams[j].trim();
  //       if (cookieParam.startsWith('expires=')) {
  //         const expirationTime = new Date(cookieParam.substring(8)).getTime();
  //         // If the expiration time is in the past, delete the cookie
  //         if (expirationTime <= currentTime) {
  //           document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;';
  //         }
  //         break; // No need to check further parameters
  //       }
  //     }
  //   }
  // }
  drawerClose(): void {
    this.drawerVisible = false;
  }

  get closeCallback() {
    return this.drawerClose.bind(this);
  }

  changePasswordDrawerVisible: boolean = false;
  changePasswordDrawerTitle: string;

  @ViewChild(ChangePasswordDrawerComponent, { static: false }) ChangePasswordDrawerComponentVar: ChangePasswordDrawerComponent;

  showChangePasswordDrawer(): void {
    this.changePasswordDrawerTitle = "Reset Password";
    this.changePasswordDrawerVisible = true;
    this.ChangePasswordDrawerComponentVar.getInfo();
  }

  changePasswordDrawerClose(): void {
    this.changePasswordDrawerVisible = false;
  }

  get changePasswordDrawerCloseCallback() {
    return this.changePasswordDrawerClose.bind(this);
  }

  size = 'large';

  intime() {
    var data = new Attendance();
    data.IN_LOCATION = "16.846520_74.612343";
    data.IN_DISTANCE = "16m";

    this.api.createAttendance(data).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Successfully Day In", "");

        if (this.userId)
          this.getTodaysDayInDayOut();

      } else {
        this.message.error("Failed to Day In", "");
      }
    });
  }

  outtime() {
    var data = new Attendance();
    data.ID = this.ID
    data.OUT_LOCATION = "16.846520_74.612343";
    data.OUT_DISTANCE = "16m";

    this.api.updateAttendance(data).subscribe(data => {
      if (data['code'] == 200) {
        this.message.success("Successfully Day Out", "");

        if (this.userId)
          this.getTodaysDayInDayOut();

      } else {
        this.message.error("Failed to Day Out", "");
      }
    });
  }

  ID: any;
  inOutData: any;
  inTime: any;
  outTime: any;
  inOut = 0;
  showBtns: null;

  getTodaysDayInDayOut() {
    this.ID = null;
    this.inOut = 0;

    var filterQuery = " AND (DATE BETWEEN '" + this.datePipe.transform(new Date(), 'yyyy-MM-dd') + "' AND '" + this.datePipe.transform(new Date(), 'yyyy-MM-dd') + "') AND";
    filterQuery += " EMPLOYEE_ID=" + this.userId + " AND";
    filterQuery += " STATUS IN ('P','L','A','HF','H','LM','SL')";


    this.api.getstatus(this.userId, '').subscribe(data1 => {
      if (data1['code'] == 200) {
        this.api.getAttendanceRegister(0, 0, 'id', 'desc', filterQuery).subscribe(data => {
          if (data['code'] == 200) {
            console.log(data);
            this.showBtns = data['data'];

            if (data['count'] > 0) {
              this.ID = data['data'][0]['ID'];
              this.inOutData = data['data'][0];
              this.inTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + 'T' + this.inOutData['IN_TIME'];
              this.outTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + 'T' + this.inOutData['OUT_TIME'];
              console.log(this.inTime);
              console.log(this.outTime);

              if (data1['message'] == "IN") {
                this.inOut = 0;

              } else if (data1['message'] == "OUT") {
                this.inOut = 1;

              } else if (data1['message'] == "DONE") {
                this.inOut = 2;
              }


              // this.api.getAttendanceRegister(0, 0, 'id', 'desc', filterQuery).subscribe(data => {
              //   if (data['code'] == 200) {
              //     console.log(data);
              //     this.showBtns = data['data'];

              //     if (data['count'] > 0) {
              //       this.ID = data['data'][0]['ID'];
              //       this.inOutData = data['data'][0];
              //       this.inTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + 'T' + this.inOutData['IN_TIME'];
              //       this.outTime = this.datePipe.transform(new Date(), "yyyy-MM-dd") + 'T' + this.inOutData['OUT_TIME'];
              //       console.log(this.inTime);
              //       console.log(this.outTime);

              //       if (data['data'][0]['OUT_LOCATION'] == '' && data['data'][0]['IN_LOCATION'] == '') {
              //         this.inOut = 0;

              //       } else if (data['data'][0]['OUT_LOCATION'] == '' && data['data'][0]['IN_LOCATION'] != '') {
              //         this.inOut = 1;

              //       } else if (data['data'][0]['OUT_LOCATION'] != '' && data['data'][0]['IN_LOCATION'] != '') {
              //         this.inOut = 2;
              //       }

              //     } else {
              //       this.inOut = 0;
              //     }

              //     this.gettingDetails(this.inOut);


              //   } else {
              //     // this.message.error("Failed to get Details", "");
              //   }

              // }, err => {
              //   if (err['ok'] == false)
              //     this.message.error("Server Not Found", "");
              // });

            } else {
              this.inOut = 0;
            }

            this.gettingDetails(this.inOut);


          } else {
            // this.message.error("Failed to get Details", "");
          }

        }, (err) => {
          if (this.api.checkOnlineStatus()) {
            console.log(err);
            this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
          } else {
            this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
          }
        });
      }

    }, (err) => {
      if (this.api.checkOnlineStatus()) {
        console.log(err);

        this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
      } else {

        this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
      }
    });


  }

  dayInBtnStatus: boolean = true;
  dayOutBtnStatus: boolean = true;

  gettingDetails(inOutStatus) {
    this.dayInBtnStatus = true;
    this.dayOutBtnStatus = true;

    if (inOutStatus == 0) {
      console.log("Not Day In");
      console.log("Not Day Out");

      this.dayOutBtnStatus = false;

    } else if (inOutStatus == 1) {
      console.log("Day In");
      console.log("Not Day Out");

      this.dayInBtnStatus = false;

    } else if (inOutStatus == 2) {
      console.log("Day In");
      console.log("Day Out");

      this.dayInBtnStatus = false;
      this.dayOutBtnStatus = false;
    }
  }

  isApkVersionModalVisible = false;
  isApkVersionModalConfirmLoading = false;
  apkVersionModalTitle: string = "";

  showApkVersionModal(): void {
    // this.isApkVersionModalVisible = true;
    // this.apkVersionModalTitle = "APK Details";

    this.api
      .getAPKInfo(
        0,
        0,
        '',
        '',
        ''


      )
      .subscribe(
        (data) => {
          if (data['code'] == 200) {
            // this.dataList = data['data'][0];
            this.PREVIOUS_VERSION = data['data'][0]['CUR_VERSION']
            this.isApkVersionModalVisible = true;
            this.apkVersionModalTitle = "APK Details";
          }
        },
        (err) => {
          if (this.api.checkOnlineStatus()) {
            console.log(err);
            this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
          } else {
            this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
          }
        }
      );
  }

  handleApkVersionModalCancel(): void {
    this.isApkVersionModalVisible = false;
    this.isApkVersionModalConfirmLoading = false;
    this.uploadProgress = 0;
    this.isProgressVisible = false;
    this.PREVIOUS_VERSION = undefined
    this.MIN_VERSION = undefined;
    this.CUR_VERSION = undefined;
    this.DESCRIPTION = ''
    this.fileURL = null;

    if (this.timer != undefined)
      this.timer.unsubscribe();
  }



  // handleApkVersionModalOk(): void {
  //   var isOk = true;

  //   if (this.MIN_VERSION != undefined) {
  //     if (this.MIN_VERSION.trim() == "") {
  //       isOk = false;
  //       console.log("MIN_VERSION")
  //       this.message.error("Please Enter Minimum Version", "");
  //     }
  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Minimum Version", "");
  //   }

  //   if (this.CUR_VERSION != undefined) {
  //     if (this.CUR_VERSION.trim() == "") {
  //       isOk = false;
  //       this.message.error("Please Enter Current Version", "");
  //     }
  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Current Version", "");
  //   }

  //   if (isOk) {
  //     console.log("MIN_VERSION")
  //     this.isApkVersionModalConfirmLoading = true;
  //     var obj1 = new Object();
  //     obj1["MIN_VERSION"] = this.MIN_VERSION;
  //     obj1["CUR_VERSION"] = this.CUR_VERSION;
  //     obj1["APK_LINK"] = this.uploadedAttachmentStr;
  //     this.apkInformationUpdate(obj1);
  //   }
  // }
  DESCRIPTION: any
  PREVIOUS_VERSION: any

  // handleApkVersionModalOk(): void {
  //   var isOk = true;
  //   if (this.PREVIOUS_VERSION != undefined) {
  //     if (this.PREVIOUS_VERSION.trim() == "") {
  //       isOk = false;
  //       this.message.error("Please Enter Previous Version", "");
  //     }
  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Previous Version", "");
  //   }
  //   if (this.MIN_VERSION != undefined) {
  //     if (this.MIN_VERSION.trim() == "") {
  //       isOk = false;
  //       console.log("MIN_VERSION")
  //       this.message.error("Please Enter Minimum Version", "");
  //     }
  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Minimum Version", "");
  //   }
  //   if (this.CUR_VERSION != undefined) {
  //     if (this.CUR_VERSION.trim() == "") {
  //       isOk = false;
  //       this.message.error("Please Enter Current Version", "");
  //     }
  //   } else {
  //     isOk = false;
  //     this.message.error("Please Enter Current Version", "");
  //   }
  //   if (isOk) {
  //     console.log("MIN_VERSION")
  //     this.isApkVersionModalConfirmLoading = true;
  //     var obj1 = new Object();
  //     obj1["PREVIOUS_VERSION"] = this.PREVIOUS_VERSION;
  //     obj1["MIN_VERSION"] = this.MIN_VERSION;
  //     obj1["CUR_VERSION"] = this.CUR_VERSION;
  //     obj1["DESCRIPTION"] = this.DESCRIPTION;
  //     obj1["USER_ID"] = this.userId;;
  //     obj1["DATETIME"] = this.datePipe.transform(new Date(), 'yyyy-MM-dd hh:mm');
  //     obj1["APK_LINK"] = this.uploadedAttachmentStr;
  //     this.apkInformationUpdate(obj1);
  //   }
  // }

  handleApkVersionModalOk(): void {
    var isOk = true;
    // if (this.PREVIOUS_VERSION != undefined) {
    //   if (this.PREVIOUS_VERSION.trim() == "") {
    //     isOk = false;
    //     this.message.error("Please Enter Previous Version", "");
    //   }
    // } else {
    //   isOk = false;
    //   this.message.error("Please Enter Previous Version", "");
    // }
    if (this.MIN_VERSION != undefined) {
      if (this.MIN_VERSION.trim() == "") {
        isOk = false;
        console.log("MIN_VERSION")
        this.message.error("Please Enter Minimum Version", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Minimum Version", "");
    }
    if (this.CUR_VERSION != undefined) {
      if (this.CUR_VERSION.trim() == "") {
        isOk = false;
        this.message.error("Please Enter Current Version", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Current Version", "");
    }
    if (this.DESCRIPTION == "" || this.DESCRIPTION == undefined || this.DESCRIPTION == null) {
      isOk = false;
      this.message.error("Please Enter Description", "");
    }
    if (isOk) {
      console.log("MIN_VERSION")
      this.isApkVersionModalConfirmLoading = true;
      var obj1 = new Object();
      obj1["PREVIOUS_VERSION"] = this.PREVIOUS_VERSION;
      obj1["MIN_VERSION"] = this.MIN_VERSION;
      obj1["CUR_VERSION"] = this.CUR_VERSION;
      obj1["DESCRIPTION"] = this.DESCRIPTION;
      obj1["USER_ID"] = this.userId;;
      obj1["DATETIME"] = this.datePipe.transform(new Date(),'yyyy-MM-dd hh:mm');
      obj1["APK_LINK"] = this.uploadedAttachmentStr;
      this.apkInformationUpdate(obj1);
    }
  }
  MIN_VERSION: any;

  // MIN_VERSION: any;
  CUR_VERSION: any;
  fileURL: File = null;

  onFileSelected(event) {
    this.fileURL = <File>event.target.files[0];
  }

  clear() {
    this.fileURL = null;
  }

  folderName = "apk";
  uploadedAttachmentStr: string;
  uploadProgress: number = 0;
  isProgressVisible: boolean = false;
  timer: any;

  imageUpload() {
    this.uploadedAttachmentStr = "";

    if (this.fileURL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      var url = "APK" + number + "." + fileExt;

      this.timer = this.api.onFileUploadWithProgress(this.folderName, this.fileURL, url).subscribe(res => {
        if (res.type === HttpEventType.Response) {
          this.isProgressVisible = false;
          this.uploadedAttachmentStr = this.api.retriveimgUrl + "apk/" + url;

        }

        if (res.type === HttpEventType.UploadProgress) {
          this.isProgressVisible = true;
          const percentDone = Math.round(100 * res.loaded / res.total);
          console.log('Progress ' + percentDone + '%');
          this.uploadProgress = percentDone;
        }

      }, err => {
        this.isApkVersionModalConfirmLoading = false;

        if (err['ok'] == false)
          this.message.error("Failed to Upload the File", "");
      });
    }
  }

  apkInformationUpdate(apkData) {
    this.api.updateGlobalSettingInfo(apkData).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.message.success("APK Information Updated Successfully", "");
        this.handleApkVersionModalCancel();

      } else {
        this.message.error("APK Information Updation Failed", "");
        this.isApkVersionModalConfirmLoading = false;
      }

    }, err => {
      this.isApkVersionModalConfirmLoading = false;

      if (err['ok'] == false)
        this.message.error("Server Not Found", "");
    });
  }

  numberWithDecimal(event: any) {
    const charCode = event.which ? event.which : event.keyCode;

    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  isDayInDayOutModalVisible = false;
  isDayInDayOutModalConfirmLoading = false;
  dayInDayOutModalTitle: string = "";

  showDayInDayOutModal(dayInDayOut): void {
    this.getCurrentPosition();
    this.isDayInDayOutModalVisible = true;

    if (dayInDayOut == "Day In")
      this.dayInDayOutModalTitle = "Day In";

    else if (dayInDayOut == "Day Out")
      this.dayInDayOutModalTitle = "Day In";
  }

  handleDayInDayOutModalCancel(): void {
    this.isDayInDayOutModalVisible = false;
    this.isDayInDayOutModalConfirmLoading = false;
  }

  getCurrentPosition() {
    var container = L.DomUtil.get('map');
    if (container != null) {
      container._leaflet_id = null;
    }

    if (!navigator.geolocation) {
      console.log("Location Not Supported");

    } else {
      this.getMyOfficeLatitudeLongitude();
      navigator.geolocation.getCurrentPosition((position) => {
        const coordinates = position.coords;
        const LatLong = [coordinates.latitude, coordinates.longitude];
        console.log(`Latitude : ${coordinates.latitude}, Longitude : ${coordinates.longitude}`);
        let map = L.map('map').setView(LatLong, 20);

        // // Street Map
        // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //   maxZoom: 19,
        //   attribution: '© OpenStreetMap'
        // }).addTo(map);

        // Satellite Map
        L.tileLayer('http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
          maxZoom: 20,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
        }).addTo(map);

        let marker = L.marker(LatLong).addTo(map);
        this.findDistance(LatLong[0], this.myOfficeLatitude, LatLong[1], this.myOfficeLongitude);
      });

      // this.watchPosition();
    }
  }

  watchPosition() {
    let destinationLat = 0;
    let destinationLong = 0;

    let id = navigator.geolocation.watchPosition((position) => {
      console.log(`Lat : ${position.coords.latitude}, Long : ${position.coords.longitude}`);

      if ((position.coords.latitude === destinationLat) && (position.coords.longitude === destinationLong)) {
        navigator.geolocation.clearWatch(id);
      }

    }, err => {
      console.log(err);

    }, {
      enableHighAccuracy: true,
      timeout: 1000,
      maximumAge: 0
    });
  }

  finalDistance = "";

  findDistance(lat1: any, lat2: any, lon1: any, lon2: any) {
    this.finalDistance = "";

    var R = 6371; // km (change this constant to get miles)
    var dLat = ((lat2 - lat1) * Math.PI) / 180;
    var dLon = ((lon2 - lon1) * Math.PI) / 180;
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    if (d > 1)
      this.finalDistance = (Math.round(d) + ' KM');

    else if (d <= 1)
      this.finalDistance = (Math.round(d * 1000) + ' M');
  }

  myOfficeLatitude: any;
  myOfficeLongitude: any;

  getMyOfficeLatitudeLongitude() {
    if (this.cookie.get('branchId') != "0") {
      this.api.getAllBranch(0, 0, 'ID', 'desc', ' AND ORG_ID=' + this.cookie.get('orgId') + " AND ID=" + this.cookie.get('branchId')).subscribe(data => {
        if (data['code'] == 200) {
          this.myOfficeLatitude = data['data'][0]["LATITUTE"];
          this.myOfficeLongitude = data['data'][0]["LONGITUTE"];
          console.log("ok")
        }

      }, (err) => {
        if (this.api.checkOnlineStatus()) {
          console.log(err);

          this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
        } else {

          this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
        }
      })
    }
  }

  handleDayInDayOutModalOk() { }

  ROLE_TYPE: string[] = this.cookie.get("loginType").split(",");
  loginRoleStatus = this.cookie.get('loginType');
  selectedRoleStatus = this.cookie.get('selectedLoginType');

  setRoleType(selectedRoleType) {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });

    if (selectedRoleType == "")
      this.cookie.set('selectedLoginType', "0,1", 365, "/", "", false, "Strict");

    else
      this.cookie.set('selectedLoginType', selectedRoleType.toString(), 365, "/", "", false, "Strict");
  }

  // QR Code
  elementType: 'url' | 'canvas' | 'img' = 'url';
  QRValue: String = "";
  isDayInDayOutModalVisibleQR = false;
  isDayInDayOutModalConfirmLoadingQR = false;
  dayInDayOutModalTitleQR: string = "";
  timeInterval: any;

  //tushar 
  generateQRCode(dayInDayOut) {
    this.isDayInDayOutModalConfirmLoadingQR = true;
    this.QRValue = "";
    this.QRValue = String(this.api.generate16DigitRandomNumber());

    var obj1 = new Object();
    obj1["DATE"] = this.datePipe.transform(new Date(), "yyyy-MM-dd");
    obj1["EMPLOYEE_ID"] = this.userId;
    obj1["ACTION"] = dayInDayOut;
    obj1["RANDOM_CODE"] = this.QRValue;

    this.api.createQRCodeInfo(obj1).subscribe(successCode => {
      if (successCode['code'] == 200) {
        this.isDayInDayOutModalConfirmLoadingQR = false;
        this.getTodaysDayInDayOut();
      } else {
        this.message.error("Failed to Generate QR Code", "");
      }
      if (successCode['code1'] == 208 || successCode['code1'] == 302) {
        this.isDayInDayOutModalVisibleQR = false;
        this.getTodaysDayInDayOut();
        this.handleDayInDayOutModalCancelQR();
      }

    }, (err) => {
      if (this.api.checkOnlineStatus()) {
        console.log(err);

        this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
      } else {

        this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
      }
    });
  }

  timeIntervalStartToGenerateQRCode(dayInDayOut) {
    this.timeInterval = setInterval(() => {
      this.generateQRCode(dayInDayOut);
    }, 10000);
  }

  showDayInDayOutModalQR(dayInDayOut): void {
    this.generateQRCode(dayInDayOut);
    this.timeIntervalStartToGenerateQRCode(dayInDayOut);

    this.isDayInDayOutModalVisibleQR = true;

    if (dayInDayOut == "Day In")
      this.dayInDayOutModalTitleQR = "Day In";

    else if (dayInDayOut == "Day Out")
      this.dayInDayOutModalTitleQR = "Day In";
  }

  handleDayInDayOutModalCancelQR(): void {
    if (this.timeInterval) {
      clearInterval(this.timeInterval);
    }

    this.isDayInDayOutModalVisibleQR = false;
    this.isDayInDayOutModalConfirmLoadingQR = false;
  }

  handleDayInDayOutModalOkQR() { }

  SELECT_BRANCH: boolean

  getbranches() {
    this.api.getAllBranch(0, 0, "NAME", "asc", "").subscribe(data => {
      if (data['code'] == 200) {
        this.BranchName = data['data'];
        console.log(this.BranchName);
        // this.onSelectAllBranch(true)
      }
    }, (err) => {
      if (this.api.checkOnlineStatus()) {
        console.log(err);

        this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
      } else {

        this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
      }
    });
  }
  onSelectAllBranch(event: any) {
    this.SELECT_BRANCH = event
    console.log(event, "Event")
    let ids = [];
    if (this.SELECT_BRANCH == true) {
      for (var i = 0; i < this.BranchName.length; i++) {
        ids.push(this.BranchName[i]["ID"]);
      }
    } else {
      ids = [];
    }
    this.BRANCH_ID = ids
    sessionStorage.setItem('BRANCH_ID', this.BRANCH_ID)
    // window.location.reload()
  }
  BranchName: any[]
  BRANCH_ID: any = []
  getallbranches() {
    sessionStorage.setItem('BRANCH_ID', this.BRANCH_ID)
  }
  clearfilter() {
    sessionStorage.removeItem('BRANCH_ID');
  }
}
