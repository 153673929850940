import { HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/Service/api.service';

@Component({
  selector: 'app-add-new-notification-drawer',
  templateUrl: './add-new-notification-drawer.component.html',
  styleUrls: ['./add-new-notification-drawer.component.css']
})

export class AddNewNotificationDrawerComponent implements OnInit {
  @Input() drawerClose: Function;
  sharingMode = "I";
  USER_IDS = [];
  TITLE = "";
  DESCRIPTION = "";
  employeeList = [];
  isLoadingOne = false
  userId = Number(this.cookie.get('userId'));
  roleId = Number(this.cookie.get('roleId'));
  orgId = Number(this.cookie.get('orgId'));
  deptId = Number(this.cookie.get('deptId'));
  branchId = Number(this.cookie.get('branchId'));
  designationId = Number(this.cookie.get('designationId'));

  heading = "";

  individualGrid = false;
  deptGrid = false;
  branchGrid = false;
  designationGrid = false;
  entireOrg = false;
  isSpinning = false;
  NOTI_TYPE = "T";
  fileName = ""
  fileUpload = ""
  constructor(private api: ApiService, private message: NzNotificationService, private cookie: CookieService) { }

  ngOnInit() { }

  reset(myForm: NgForm) {
    myForm.form.reset();
  }

  close(myForm: NgForm): void {
    this.drawerClose();
    this.reset(myForm);
    this.fileURL = null;
    this.imageURL = null
    this.photoURL = null
    this.uploadedAttachmentStr = "";
    // this.clear();
  }
  notiChange(event: any) {
    this.NOTI_TYPE = event
    if (this.NOTI_TYPE == 'F') {
      this.fileName = "Upload File"
    }
    else if (this.NOTI_TYPE == 'V') {
      this.fileName = "Upload Video"
    }
    else if (this.NOTI_TYPE == 'I') {
      this.fileName = "Upload Image"
    }
  }

  btnIndividualStatus = false;
  btnDepartmentStatus = false;
  btnBranchStatus = false;
  btnDesignationStatus = false;
  btnEntireOrganisationStatus = false;

  disableRadioButtons() {
    if (this.roleId == 12) {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = true;
      this.btnBranchStatus = true;
      this.btnDesignationStatus = true;
      this.btnEntireOrganisationStatus = true;

    } else {
      this.btnIndividualStatus = true;
      this.btnDepartmentStatus = false;
      this.btnBranchStatus = false;
      this.btnDesignationStatus = false;
      this.btnEntireOrganisationStatus = false;

      if (this.deptId == 0) {
        this.btnDepartmentStatus = true;
      }

      if (this.designationId == 0) {
        this.btnDesignationStatus = true;
      }

      if (this.branchId == 0) {
        this.btnBranchStatus = true;
      }
    }
  }

  changeRadioButton(btnValue) {
    this.USER_IDS = [];
    this.employeeList = [];

    if (btnValue == 'I') {
      this.heading = "Select Employee";

      this.individualGrid = true;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = false;

      this.api.getNotiSubordinateList(this.userId, this.roleId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);

        }

      }, (err) => {
        if (this.api.checkOnlineStatus()) {
          console.log(err);

          this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
        } else {

          this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
        }
      })

    } else if (btnValue == 'D') {
      this.heading = "Select Department";

      this.individualGrid = false;
      this.deptGrid = true;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = false;

      this.api.getNotiSubordinateDepartmentList(this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);

        }

      }, (err) => {
        if (this.api.checkOnlineStatus()) {
          console.log(err);

          this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
        } else {

          this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
        }
      })

    } else if (btnValue == 'B') {
      this.heading = "Select Branch";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = true;
      this.designationGrid = false;
      this.entireOrg = false;

      this.api.getNotiSubordinateBranchList(this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);

        }

      }, (err) => {
        if (this.api.checkOnlineStatus()) {
          console.log(err);

          this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
        } else {

          this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
        }
      })

    } else if (btnValue == 'DE') {
      this.heading = "Select Designation";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = true;
      this.entireOrg = false;

      this.api.getNotiSubordinateDesignationList(this.userId, this.orgId).subscribe(data => {
        if (data['code'] == 200) {
          this.employeeList = data['data'];
          console.log(this.employeeList);

        }

      }, (err) => {
        if (this.api.checkOnlineStatus()) {
          console.log(err);

          this.message.error("The server's internet connection is down. Please contact the EDP department for help.", '');
        } else {

          this.message.error("Cannot perform operation due to unstable Internet connection. ", "");
        }
      })

    } else if (btnValue == 'EO') {
      this.heading = "Select Employee";

      this.individualGrid = false;
      this.deptGrid = false;
      this.branchGrid = false;
      this.designationGrid = false;
      this.entireOrg = true;
    }
  }

  imageURL

  getInitial(empName) {
    let initial: string = empName.charAt(0);
    return initial.trim();
  }

  save(myForm: NgForm): void {
    var isOk = true;

    if (!this.entireOrg) {
      if (this.USER_IDS.length == 0) {
        isOk = false;

        if (this.individualGrid) {
          this.message.error("Please Select Valid Employee", "");

        } else if (this.deptGrid) {
          this.message.error("Please Select Valid Department", "");

        } else if (this.branchGrid) {
          this.message.error("Please Select Valid Branch", "");

        } else if (this.designationGrid) {
          this.message.error("Please Select Valid Designation", "");
        }
      }
    }

    if (this.TITLE != undefined) {
      if (this.TITLE.trim() != '') {
        // if (!this.api.checkTextBoxIsValid(this.TITLE)) {
        //   isOk = false;
        //   this.message.error("Please Enter Valid Notification Title", "");
        // }
      } else {
        isOk = false;
        this.message.error("Please Enter Valid Notification Title", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Notification Title", "");
    }

    if (this.DESCRIPTION != undefined) {
      if (this.DESCRIPTION.trim() == '') {
        isOk = false;
        this.message.error("Please Enter Valid Notification Description", "");
      }
    } else {
      isOk = false;
      this.message.error("Please Enter Valid Notification Description", "");
    }

    if (this.NOTI_TYPE == 'F') {
      if (this.fileURL == null) {
        isOk = false;
        this.message.error("Please Select Valid File", "");
      }
    }
    if (this.NOTI_TYPE == 'I') {
      if (this.fileURL == null) {
        isOk = false;
        this.message.error("Please Select Valid Image", "");
      }
    }
    if (this.NOTI_TYPE == 'V') {
      if (this.fileURL == null) {
        isOk = false;
        this.message.error("Please Select Valid Video", "");
      }
    }

    if (isOk) {
      this.imageURL = "";
      // this.imageUpload();
      // this.uploadfile(true)
      this.imageURL = this.uploadedAttachmentStr;

      if (this.individualGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['EMPLOYEE_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['EMPLOYEE_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 1, a, this.orgId, this.imageURL, this.NOTI_TYPE).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('I');
            this.close(myForm);
            this.imageURL = null
            this.uploadedAttachmentStr = "";
            this.photoURL = null
            this.fileURL = null

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.deptGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['DEPARTMENT_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['DEPARTMENT_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 2, a, this.orgId, this.imageURL, this.NOTI_TYPE).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('D');
            this.close(myForm);
            this.photoURL = null
            this.imageURL = null
            this.uploadedAttachmentStr = "";
          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.branchGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['BRANCH_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['BRANCH_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 3, a, this.orgId, this.imageURL, this.NOTI_TYPE).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('B');
            this.close(myForm);
            this.photoURL = null
            this.imageURL = null
            this.uploadedAttachmentStr = "";

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.designationGrid) {
        this.isSpinning = true;
        var a = [];

        if (this.USER_IDS.length != 0) {
          for (var i = 0; i < this.USER_IDS.length; i++) {
            var obj1 = new Object();
            obj1['DESIGNATION_ID'] = this.USER_IDS[i];
            a.push(Object.assign({}, obj1));
          }

        } else {
          var obj1 = new Object();
          obj1['DESIGNATION_ID'] = this.USER_IDS;
          a.push(Object.assign({}, obj1));
        }

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 4, a, this.orgId, this.imageURL, this.NOTI_TYPE).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('DE');
            this.close(myForm);
            this.imageURL = null
            this.photoURL = null
            this.uploadedAttachmentStr = "";

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });

      } else if (this.entireOrg) {
        this.isSpinning = true;
        var a = [];

        var obj1 = new Object();
        obj1['ORG_ID'] = this.orgId;
        a.push(Object.assign({}, obj1));

        this.api.notiDetailsAddBulk(this.userId, this.TITLE, this.DESCRIPTION, 5, a, this.orgId, this.imageURL, this.NOTI_TYPE).subscribe(successCode => {
          if (successCode['code'] == "200") {
            this.isSpinning = false;
            this.message.success("Notification Send Successfully", "");
            this.changeRadioButton('EO');
            this.close(myForm);
            this.uploadedAttachmentStr = "";
            this.photoURL = null
            this.imageURL = null

          } else {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }

        }, err => {
          if (err['ok'] == false) {
            this.isSpinning = false;
            this.message.error("Failed to Send Notification", "");
          }
        });
      }
      myForm.reset()
    }
  }

  fileURL: File = null;
  photoURL: any;
  imageSrc1: string;
  videoUrl: File = null
  ATTACHMENT: any

  // onFileSelected(event: any) {
  //   if (this.NOTI_TYPE == 'F') {
  //     if (event.target.files[0].type != 'video/mp4' ||
  //       event.target.files[0].type == 'video') {
  //       this.fileURL = <File>event.target.files[0];
  //       const reader = new FileReader();
  //       if (event.target.files && event.target.files.length) {
  //         const [file] = event.target.files;
  //         this.imageUpload()
  //         reader.readAsDataURL(file);
  //       }
  //     } else {
  //       this.message.error("Please ckeck file type ", "");
  //       this.fileURL = null;
  //     }
  //   }
  //   if (this.NOTI_TYPE == 'I') {
  //     if (event.target.files[0].type != 'video/mp4' ||
  //       event.target.files[0].type == 'video') {
  //       this.fileURL = <File>event.target.files[0];
  //       const reader = new FileReader();
  //       if (event.target.files && event.target.files.length) {
  //         const [file] = event.target.files;
  //         this.imageUpload()
  //         reader.readAsDataURL(file);
  //         if (event.target.files[0].type == "image/jpeg" ||
  //           event.target.files[0].type == "image/png" ||
  //           event.target.files[0].type == "image/jpg") {
  //           reader.onload = () => {
  //             this.imageSrc1 = reader.result as string;
  //             this.photoURL = this.imageSrc1;
  //           };
  //         }
  //       }
  //     } else {
  //       this.message.error("Please ckeck file type ", "");
  //       this.fileURL = null;
  //     }
  //   }
  //   if (this.NOTI_TYPE == 'V') {
  //     if (event.target.files[0].type == 'video/mp4' ||
  //       event.target.files[0].type == 'video') {
  //       this.fileURL = <File>event.target.files[0];
  //       this.imageUpload()
  //       const reader = new FileReader();
  //       if (event.target.files && event.target.files.length) {
  //         const [file] = event.target.files;
  //         reader.readAsDataURL(file);
  //       }
  //     }
  //     else {
  //       this.message.error('Please select only video file', '')
  //     }
  //   }
  // }

  onFileSelected(event: any) {
    if (this.NOTI_TYPE == 'F') {
      if (event.target.files[0].type != 'video/mp4' ||
        event.target.files[0].type == 'video') {
        this.fileURL = <File>event.target.files[0];
        const reader = new FileReader();
        const fileSizeInMB = event.target.files[0].size / 1024 / 1024; // Calculate size in MB

        // Check if the file size is less than 10 MB
        if (fileSizeInMB <= 10) {
          if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            // this.imageUpload()
            reader.readAsDataURL(file);

          }
        } else {
          this.fileURL = null
          this.message.error('File size must be less than or equal to 10 MB', '')
        }

      } else {
        this.message.error("Please ckeck file type ", "");
        this.fileURL = null;
      }
    }
    if (this.NOTI_TYPE == 'I') {
      if (event.target.files[0].type != 'video/mp4' ||
        event.target.files[0].type == 'video') {
        this.fileURL = <File>event.target.files[0];
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          this.imageUpload()
          reader.readAsDataURL(file);
          if (event.target.files[0].type == "image/jpeg" ||
            event.target.files[0].type == "image/png" ||
            event.target.files[0].type == "image/jpg") {
            reader.onload = () => {
              this.imageSrc1 = reader.result as string;
              this.photoURL = this.imageSrc1;
            };
          }
        }
      } else {
        this.message.error("Please ckeck file type ", "");
        this.fileURL = null;
      }
    }
    if (this.NOTI_TYPE == 'V') {
      if (event.target.files[0].type == 'video/mp4' ||
        event.target.files[0].type == 'video') {
        this.fileURL = <File>event.target.files[0];
        this.imageUpload()
        const reader = new FileReader();
        if (event.target.files && event.target.files.length) {
          const [file] = event.target.files;
          reader.readAsDataURL(file);
        }
      }
      else {
        this.message.error('Please select only video file', '')
      }
    }
  }

  folderName = "notifications";
  uploadedAttachmentStr: string;

  imageUpload() {
    this.uploadedAttachmentStr = "";
    if (this.fileURL) {
      var number = Math.floor(100000 + Math.random() * 900000);
      var fileExt = this.fileURL.name.split('.').pop();
      // var url = "N" + number + "." + fileExt;
      var url = this.fileURL.name
      this.isLoadingOne = true
      this.api.onUpload2(this.folderName, this.fileURL, url).subscribe(res => {
        if (res["code"] == 200) {
          this.message.success(this.fileURL.name + '' + ' file uploaded successfully.', '')
          this.isLoadingOne = false
        } else {
          this.message.error('Something went wrong', '')
        }
      });
      this.uploadedAttachmentStr = this.api.retriveimgUrl + "notifications/" + url;
    } else {
      this.uploadedAttachmentStr = "";
    }
  }

  clear() {
    this.message.warning(this.fileURL.name + '' + ' file removed !', '')
    this.fileURL = null;
    this.photoURL = null;
  }

}
