export class ActivityLeadMaster {
    CUSTOMER_ID: any;
    ID: any;
    CLIENT_ID: any;
    ACTIVITY_TYPE_ID: any;
    ACTIVITY_ID: any;
    ACTION_ID;
    EXECUTIVE_ID;
    CALL_STATUS: string
    CALLING_DATE_TIME
    RESCHEDULE_DATE_TIME: any
    FIELD_1;
    FIELD_2;
    FIELD_3;
    SHEET_ID
    ASSIGNED_ID
    CCE_ID
    REMARK
    FIELD_4;
    FIELD_5;
    RESHEDULED_DATE
    RESHEDULED_TIME:any = new Date()
    BT_NUMBER
    BOOKING_DATE: any
    BT_DATE_TIME: any
    BOOKING_TIME: any
    CALLING_TIME:any = new Date()

    REMINDER_DATE:any = new Date()
    REMINDER_TIME

    SELF_REMINDER_DATE
    SELF_REMINDER_TIME

    CURRENT_READING
    LOST_SUB_CATEGOREY

    REASON
    EXPRESS_CARE
    WORK_TYPE
    CALLING_STATUS
    RO_NUMBER
    RO_DATE

    IS_PICK_UP_AND_DROP
    PICK_UP_DATE
    PICK_UP_TIME
    SPECIAL_MESSAGE
    // EXPRESS_CARE

    CALL_TYPE: any
    LOCATION: any
    CALLED_BY: any

    IS_VIDEO_CALL
    IS_LIVE_STREAMING
    CALLED_BY_NAME
    CATEGORY_STATUS
    IS_REGISTERED_WITH_MAI
    IS_WARRENTY_AVAILED
    IS_SOT_AVAILED
    IS_SOT_SUPER_AVAILED
    IS_PUC_AVAILED
    IS_INSURANCE_AVAILED
    IS_RSA_AVAILED
    IS_CONFIRMATION_DONE
    SERVICE_ADVISER_ID
    CUSTOMER_NAME
    MOBILE_NUMBER
    NOTIFICATION_ID
    APPOINTMENT_BRANCH : any
    PICK_UP_AND_DROP
    IS_ADDED_BY_HMIL
    HMIL_LOST_STATUS
    ASSIGNED_DATE
}


