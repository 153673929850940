export class schedule{

    ID:number;
    TYPES:string='D';
    TIME:any=[];
    REPORT_ID:any;
    MAIL_LIST:any;
    DAYS:any;
    MONTHS:any;
    YEARS:any=[];
    CUSTOM:any;
    MAIL_LIST_CAPTION:any
    STATUS:boolean
    CLIENT_ID:number
    TYPE_OF_ATTACHMENT:any
    FILTER_QUERY: any;
    EMPLOYEE_ID:number
}