import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-insurance-noti',
  templateUrl: './insurance-noti.component.html',
  styleUrls: ['./insurance-noti.component.css']
})
export class InsuranceNotiComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
